<template>
  <v-app id="app">
    <ClientInformationForm />
  </v-app>
</template>

<script>
import ClientInformationForm from "./components/ClientInformationForm/ClientInformationForm.vue";

export default {
  name: "App",

  components: { ClientInformationForm },

  created() {
    this.setWindowWidth();
    window.addEventListener("resize", this.setWindowWidth, true);
  },

  destroyed() {
    window.removeEventListener("resize", this.setWindowWidth, true);
  },

  methods: {
    setWindowWidth() {
      this.$store.commit("SET_WINDOW_WIDTH", window.innerWidth);
    }
  }
};
</script>
