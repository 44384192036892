<template>
  <v-slide-y-transition>
    <v-container
      v-if="!loading && !hasSentData"
      class="py-2 px-4 light-grey"
      style="min-height: 100vh"
    >
      <v-img
        max-width="121"
        src="@/assets/img/logo-mobile.webp"
        alt="Logo Reis Pinto"
        class="mb-5"
      />

      <h1 class="dark-purple--text fs-20 mb-4">
        Estamos aqui para solucionar de vez o seu problema.
      </h1>
      <p class="dark-grey--text mb-4">
        Para prosseguirmos, preencha abaixo com suas informações pessoais.
      </p>

      <v-container
        style="background-color: rgba(103, 52, 137, 0.1); border-radius: 8px"
        class="mb-8"
      >
        <v-row align="center">
          <v-col cols="auto" class="pr-0">
            <v-icon color="dark-purple">mdi-alert-outline</v-icon>
          </v-col>
          <v-col>
            <div>
              <span class="font-weight-medium dark-purple--text fs-12">
                Digite seus dados corretamente para que possamos salvá-los com
                segurança.
              </span>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-fade-transition>
        <div v-show="currentStep === 0">
          <!-- Form passo 1 -->
          <h2 class="dark-grey--text fs-18 text-center mb-6">
            Passo 1: Dados Pessoais
          </h2>

          <v-form ref="form1">
            <!-- Nome -->
            <v-text-field
              solo
              flat
              dense
              validate-on-blur
              v-model="nome"
              :rules="nomeRules"
              label="Seu nome completo"
              required
            />

            <!-- E-mail -->
            <v-text-field
              solo
              flat
              dense
              validate-on-blur
              v-model="email"
              :rules="emailRules"
              label="Seu e-mail"
              type="email"
              required
            />

            <!-- Celular -->
            <v-text-field
              solo
              flat
              dense
              validate-on-blur
              v-model="celular"
              :rules="celularRules"
              label="Seu celular com DDD e dígito 9"
              type="tel"
              required
              v-mask="['(##) ####-####', '(##) #####-####']"
            />

            <!-- CPF -->
            <v-text-field
              solo
              flat
              dense
              validate-on-blur
              v-model="cpf"
              :rules="cpfRules"
              label="Nº do CPF"
              required
              v-mask="'###.###.###-##'"
            />
          </v-form>
        </div>
      </v-fade-transition>

      <v-fade-transition>
        <div v-show="currentStep === 1">
          <!-- Form passo 2 -->
          <h2 class="dark-grey--text fs-18 text-center mb-6">
            Passo 2: Endereço
          </h2>
          <v-form ref="form2">
            <!-- CEP -->
            <v-text-field
              solo
              flat
              dense
              validate-on-blur
              v-model="cep"
              :rules="cepRules"
              :disabled="fetchingCepData"
              label="CEP"
              required
              @blur="fetchCepData"
              v-mask="'#####-###'"
            />
            <!-- Logradouro -->
            <v-text-field
              solo
              flat
              dense
              validate-on-blur
              v-model="logradouro"
              :rules="logradouroRules"
              :disabled="fetchingCepData"
              label="Nome da Rua"
              required
            />
            <!-- Número -->
            <v-text-field
              solo
              flat
              dense
              validate-on-blur
              v-model="numero"
              :rules="numeroRules"
              ref="numero"
              label="Número"
              v-mask="['#', '##', '###', '####', '#####', '######']"
              required
            />
            <!-- Complemento -->
            <v-text-field solo flat v-model="complemento" label="Complemento" />
            <!-- Bairro -->
            <v-text-field
              solo
              flat
              dense
              validate-on-blur
              v-model="bairro"
              :rules="bairroRules"
              :disabled="fetchingCepData"
              label="Bairro"
              required
            />
            <!-- Cidade -->
            <v-text-field
              solo
              flat
              dense
              validate-on-blur
              v-model="cidade"
              :rules="cidadeRules"
              :disabled="fetchingCepData"
              label="Cidade"
              required
            />
            <!-- Estado -->
            <v-select
              solo
              flat
              dense
              validate-on-blur
              v-model="estado"
              :items="estadoOptions"
              :rules="estadoRules"
              :disabled="fetchingCepData"
              label="Estado"
              required
            />
          </v-form>
        </div>
      </v-fade-transition>

      <v-btn
        @click="onConfirmButtonClick"
        class="btn-submit my-4 fs-16"
        :disabled="buttonDisabled"
        block
      >
        {{ currentStep === 0 ? 'Próximo passo' : 'Finalizar' }}
      </v-btn>

      <v-btn
        v-if="currentStep === 1"
        @click="onBackButtonClick"
        class="btn-submit-outlined mb-8 fs-16"
        block
      >
        Voltar
      </v-btn>
    </v-container>

    <!-- Container form enviado -->
    <v-container
      v-else
      fluid
      style="height: 100vh; width: 100vw"
      class="dark-purple"
    >
      <div class="text-center py-2">
        <v-img
          class="mx-auto"
          max-width="121px"
          src="@/assets/img/logo.webp"
          alt="Logo Reis Pinto"
        />
      </div>
      <div
        class="d-flex justify-center align-center"
        style="height: calc(100vh - 145px)"
      >
        <v-fade-transition>
          <!-- Loading -->
          <v-progress-circular
            v-if="loading"
            indeterminate
            size="50"
            color="white"
          />

          <!-- Mensagem de confirmação -->
          <div
            v-else-if="hasSentData"
            class="text-center pa-4"
            id="confirmation-message"
          >
            <v-icon color="white" size="44" class="mb-4">
              mdi-check-circle-outline
            </v-icon>
            <h1 class="mb-3">Agradecemos por enviar essas informações.</h1>
            <p>Daremos seguimento ao seu processo imediatamente.</p>
          </div>
        </v-fade-transition>
      </div>
    </v-container>
  </v-slide-y-transition>
</template>

<script>
import axios from 'axios'
import { customFields } from '@/constants'
import { mask } from 'vue-the-mask'

export default {
  directives: { mask },

  data() {
    return {
      loading: false,
      hasSentData: false,
      buttonDisabled: false,
      nome: '',
      nomeRules: [
        v => !!v || 'Por favor, digite seu nome',
        v => v.split(' ').length > 1 || 'Por favor, digite o nome completo'
      ],
      email: '',
      emailRules: [
        v => !!v || 'Por favor, digite seu e-mail',
        v => this.validateEmail(v) || 'Email inválido'
      ],
      celular: '',
      celularRules: [
        v => !!v || 'Por favor, digite seu celular',
        v =>
          v.replace(/\D+/g, '').length === 10 ||
          v.replace(/\D+/g, '').length === 11 ||
          'Celular inválido'
      ],
      cpf: '',
      cpfRules: [
        v => !!v || 'Por favor, digite seu CPF',
        v => this.validateCpf(v) || 'CPF inválido'
      ],
      cep: '',
      cepRules: [
        v => !!v || 'Por favor, digite seu CEP',
        v => v.replace(/\D+/g, '').length === 8 || 'CEP inválido'
      ],
      logradouro: '',
      logradouroRules: [v => !!v || 'Por favor, digite o logradouro'],
      numero: '',
      numeroRules: [v => !!v || 'Por favor, digite o número'],
      complemento: '',
      bairro: '',
      bairroRules: [v => !!v || 'Por favor, digite o bairro'],
      cidade: '',
      cidadeRules: [v => !!v || 'Por favor, digite a cidade'],
      estado: '',
      estadoOptions: [
        'AC',
        'AL',
        'AM',
        'AP',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RO',
        'RS',
        'RR',
        'SC',
        'SE',
        'SP',
        'TO'
      ],
      estadoRules: [v => !!v || 'Por favor, selecione o estado'],
      fetchingCepData: false,

      currentStep: 0
    }
  },

  computed: {
    isFirstStep() {
      return this.currentStep === 0
    }
  },

  methods: {
    async onConfirmButtonClick() {
      this.buttonDisabled = true
      const formIsValid = this.isFirstStep
        ? this.$refs.form1.validate()
        : this.$refs.form2.validate()
      if (!formIsValid) {
        this.buttonDisabled = false
        return
      }
      if (this.isFirstStep) this.currentStep++
      else await this.createLead()
      this.buttonDisabled = false
    },

    async onBackButtonClick() {
      this.currentStep--
      await this.$nextTick()
      this.celular = ''
      this.$refs.form1.resetValidation()
    },

    async createLead() {
      this.loading = true
      try {
        const PIPELINE_FUNIL_DE_VENDAS_ID = 4136801
        const STATUS_ENVIOU_DADOS_PROCURACAO_ID = 40902275
        const currentDatetime = Math.floor(Date.now() / 1000)
        const lead = {
          pipeline_id: PIPELINE_FUNIL_DE_VENDAS_ID,
          status_id: STATUS_ENVIOU_DADOS_PROCURACAO_ID,
          created_by: 0,
          updated_by: 0,
          created_at: currentDatetime,
          updated_at: currentDatetime,
          custom_fields_values: this.generateLeadCustomFields()
        }
        const contact = {
          name: this.nome.toUpperCase(),
          first_name: this.nome.toUpperCase(),
          created_by: 0,
          updated_by: 0,
          created_at: currentDatetime,
          updated_at: currentDatetime,
          custom_fields_values: this.generateContactCustomFields()
        }
        await axios.post('/api/forms/form-dados-procuracao', { lead, contact })
        this.hasSentData = true
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },

    async fetchCepData() {
      this.fetchingCepData = true
      try {
        const cep = this.cep.replace(/\D+/g, '')
        if (cep.length !== 8) throw Error('CEP inválido')
        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        )
        this.logradouro = data.logradouro
        this.bairro = data.bairro
        this.cidade = data.localidade
        this.estado = data.uf
      } catch (error) {
        console.error(error)
      } finally {
        this.fetchingCepData = false
      }
    },

    generateLeadCustomFields() {
      const mapFieldNameToData = {
        ['CEP']: this.cep,
        ['Logradouro']: this.logradouro,
        ['Número']: this.numero,
        ['Complemento']: this.complemento,
        ['Bairro']: this.bairro,
        ['Cidade']: this.cidade,
        ['Estado']: this.estado
      }
      return customFields['lead']
        .filter(cf => mapFieldNameToData[cf.name])
        .map(cf => ({
          field_id: cf.id,
          values: [{ value: mapFieldNameToData[cf.name] }]
        }))
    },

    generateContactCustomFields() {
      const mapFieldNameToData = {
        ['CPF']: this.cpf,
        ['E-mail']: this.email,
        ['Celular']: '+55' + this.celular.replace(/\D+/g, '')
      }
      return customFields['contact']
        .filter(cf => mapFieldNameToData[cf.name])
        .map(cf => ({
          field_id: cf.id,
          values: [{ value: mapFieldNameToData[cf.name], enum_id: cf.enum_id }]
        }))
    },

    validateEmail(email) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    },

    validateCpf(strCPF) {
      strCPF = strCPF.replace(/\D+/g, '')
      if (strCPF.length !== 11) return false
      if (strCPF == '00000000000') return false
      let resto,
        soma = 0
      for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
      resto = (soma * 10) % 11
      if (resto == 10 || resto == 11) resto = 0
      if (resto != parseInt(strCPF.substring(9, 10))) return false
      soma = 0
      for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
      resto = (soma * 10) % 11
      if (resto == 10 || resto == 11) resto = 0
      if (resto != parseInt(strCPF.substring(10, 11))) return false
      return true
    }
  }
}
</script>

<style scoped>
#confirmation-message h1 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 1.25rem;
  color: white;
}

#confirmation-message p {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1rem;
  color: white;
}
</style>
