<template>
  <v-slide-y-transition>
    <v-container fluid class="pa-0" v-if="!loading && !hasSentData">
      <v-row style="min-height: 100vh" no-gutters>
        <!-- Coluna esquerda -->
        <v-col
          cols="5"
          class="dark-purple d-flex flex-column justify-space-between"
          style="overflow: auto"
        >
          <div class="mx-auto py-6">
            <v-img
              contain
              max-width="240"
              src="@/assets/img/logo.webp"
              alt="Logo Reis Pinto"
            />
          </div>

          <div class="py-0">
            <v-img
              eager
              max-width="100%"
              max-height="400px"
              src="@/assets/img/surface1.webp"
              alt="Superfície 1"
            />
          </div>
        </v-col>

        <!-- Coluna direita -->
        <v-col cols="7" class="light-grey">
          <div style="max-width: 588px" class="mx-auto py-16 px-6">
            <h1 class="fs-32 dark-purple--text mb-6">
              Estamos aqui para solucionar de vez o seu problema.
            </h1>
            <p class="fs-24 grey-dark--text mb-4">
              Para prosseguirmos, preencha abaixo com suas informações pessoais.
            </p>
            <v-container
              style="background-color: rgba(103, 52, 137, 0.1); border-radius: 8px"
              class="mb-10"
            >
              <v-row align="center">
                <v-col cols="auto" class="pr-0">
                  <v-icon color="dark-purple">mdi-alert-outline</v-icon>
                </v-col>
                <v-col>
                  <div>
                    <span class="font-weight-medium dark-purple--text">
                      Digite seus dados corretamente para que possamos salvá-los
                      com segurança.
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-container>

            <!-- Form -->
            <v-form ref="form" id="form">
              <!-- Nome -->
              <v-text-field
                solo
                flat
                validate-on-blur
                dense
                v-model="nome"
                :rules="nomeRules"
                label="Seu nome completo"
                required
              />

              <v-row>
                <v-col>
                  <!-- E-mail -->
                  <v-text-field
                    solo
                    flat
                    validate-on-blur
                    dense
                    v-model="email"
                    :rules="emailRules"
                    label="Seu e-mail"
                    required
                  />
                </v-col>
                <v-col>
                  <!-- Celular -->
                  <v-text-field
                    solo
                    flat
                    validate-on-blur
                    dense
                    v-model="celular"
                    :rules="celularRules"
                    label="Seu celular com DDD e dígito 9"
                    required
                    v-mask="['(##) ####-####', '(##) #####-####']"
                  />
                </v-col>
              </v-row>

              <!-- CPF -->
              <v-text-field
                solo
                flat
                validate-on-blur
                dense
                v-model="cpf"
                :rules="cpfRules"
                label="Nº do CPF"
                required
                v-mask="'###.###.###-##'"
              />

              <v-row>
                <v-col>
                  <!-- CEP -->
                  <v-text-field
                    solo
                    flat
                    validate-on-blur
                    dense
                    v-model="cep"
                    :rules="cepRules"
                    :disabled="fetchingCepData"
                    label="CEP"
                    required
                    @blur="fetchCepData"
                    v-mask="'#####-###'"
                  />
                </v-col>
                <v-col>
                  <!-- Logradouro -->
                  <v-text-field
                    solo
                    flat
                    validate-on-blur
                    dense
                    v-model="logradouro"
                    :rules="logradouroRules"
                    :disabled="fetchingCepData"
                    label="Nome da Rua"
                    required
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <!-- Número -->
                  <v-text-field
                    solo
                    flat
                    validate-on-blur
                    dense
                    v-model="numero"
                    :rules="numeroRules"
                    label="Número"
                    required
                  />
                </v-col>
                <v-col>
                  <!-- Complemento -->
                  <v-text-field
                    solo
                    flat
                    validate-on-blur
                    dense
                    v-model="complemento"
                    label="Complemento"
                  />
                </v-col>
                <v-col>
                  <!-- Bairro -->
                  <v-text-field
                    solo
                    flat
                    validate-on-blur
                    dense
                    v-model="bairro"
                    :rules="bairroRules"
                    :disabled="fetchingCepData"
                    label="Bairro"
                    required
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <!-- Cidade -->
                  <v-text-field
                    solo
                    flat
                    validate-on-blur
                    dense
                    v-model="cidade"
                    :rules="cidadeRules"
                    :disabled="fetchingCepData"
                    label="Cidade"
                    required
                  />
                </v-col>
                <v-col>
                  <!-- Estado -->
                  <v-select
                    solo
                    flat
                    validate-on-blur
                    dense
                    v-model="estado"
                    :items="estadoOptions"
                    :rules="estadoRules"
                    :disabled="fetchingCepData"
                    label="Estado"
                    required
                  />
                </v-col>
              </v-row>
            </v-form>

            <!-- Botão Confirmar envio -->
            <v-btn
              @click="createLead"
              type="button"
              class="btn-submit mt-4"
              :disabled="buttonDisabled"
              block
              dark
            >
              Confirmar envio
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- Container form enviado -->
    <v-container
      v-else
      fluid
      style="height: 100vh; width: 100vw"
      class="dark-purple"
    >
      <div class="text-center py-6">
        <v-img
          class="mx-auto"
          max-width="240"
          src="@/assets/img/logo.webp"
          alt="Logo Reis Pinto"
        />
      </div>
      <div
        class="d-flex justify-center align-center"
        style="height: calc(100vh - 334px)"
      >
        <v-fade-transition>
          <!-- Loading -->
          <v-progress-circular
            v-if="loading"
            indeterminate
            size="80"
            color="white"
          />

          <!-- Mensagem de confirmação -->
          <div
            v-else-if="hasSentData"
            class="text-center pa-6"
            id="confirmation-message"
          >
            <v-icon color="white" size="66" class="mb-5">
              mdi-check-circle-outline
            </v-icon>
            <h1 class="mb-3">Agradecemos por enviar essas informações.</h1>
            <p>Daremos seguimento ao seu processo imediatamente.</p>
          </div>
        </v-fade-transition>
      </div>
    </v-container>
  </v-slide-y-transition>
</template>

<script>
import axios from 'axios'
import { customFields } from '@/constants'
import { mask } from 'vue-the-mask'

export default {
  directives: { mask },

  data() {
    return {
      loading: false,
      hasSentData: false,
      buttonDisabled: false,
      nome: '',
      nomeRules: [
        v => !!v || 'Por favor, digite seu nome',
        v => v.split(' ').length > 1 || 'Por favor, digite o nome completo'
      ],
      email: '',
      emailRules: [
        v => !!v || 'Por favor, digite seu e-mail',
        v => this.validateEmail(v) || 'Email inválido'
      ],
      celular: '',
      celularRules: [
        v => !!v || 'Por favor, digite seu celular',
        v =>
          v.replace(/\D+/g, '').length === 10 ||
          v.replace(/\D+/g, '').length === 11 ||
          'Celular inválido'
      ],
      cpf: '',
      cpfRules: [
        v => !!v || 'Por favor, digite seu CPF',
        v => this.validateCpf(v) || 'CPF inválido'
      ],
      cep: '',
      cepRules: [
        v => !!v || 'Por favor, digite seu CEP',
        v => v.replace(/\D+/g, '').length === 8 || 'CEP inválido'
      ],
      logradouro: '',
      logradouroRules: [v => !!v || 'Por favor, digite o logradouro'],
      numero: '',
      numeroRules: [v => !!v || 'Por favor, digite o número'],
      complemento: '',
      bairro: '',
      bairroRules: [v => !!v || 'Por favor, digite o bairro'],
      cidade: '',
      cidadeRules: [v => !!v || 'Por favor, digite a cidade'],
      estado: '',
      estadoOptions: [
        'AC',
        'AL',
        'AM',
        'AP',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RO',
        'RS',
        'RR',
        'SC',
        'SE',
        'SP',
        'TO'
      ],
      estadoRules: [v => !!v || 'Por favor, selecione o estado'],
      fetchingCepData: false
    }
  },

  methods: {
    async createLead() {
      this.buttonDisabled = true
      const formIsValid = this.$refs.form.validate()
      if (!formIsValid) {
        this.buttonDisabled = false
        return
      }
      this.loading = true
      try {
        const PIPELINE_FUNIL_DE_VENDAS_ID = 4136801
        const STATUS_ENVIOU_DADOS_PROCURACAO_ID = 40902275
        const currentDatetime = Math.floor(Date.now() / 1000)
        const lead = {
          pipeline_id: PIPELINE_FUNIL_DE_VENDAS_ID,
          status_id: STATUS_ENVIOU_DADOS_PROCURACAO_ID,
          created_by: 0,
          updated_by: 0,
          created_at: currentDatetime,
          updated_at: currentDatetime,
          custom_fields_values: this.generateLeadCustomFields()
        }
        const contact = {
          name: this.nome.toUpperCase(),
          first_name: this.nome.toUpperCase(),
          created_by: 0,
          updated_by: 0,
          created_at: currentDatetime,
          updated_at: currentDatetime,
          custom_fields_values: this.generateContactCustomFields()
        }
        await axios.post('/api/forms/form-dados-procuracao', { lead, contact })
        this.hasSentData = true
      } catch (error) {
        this.buttonDisabled = false
        console.error(error)
      } finally {
        this.loading = false
      }
    },

    async fetchCepData() {
      this.fetchingCepData = true
      try {
        const cep = this.cep.replace(/\D+/g, '')
        if (cep.length !== 8) throw Error('CEP inválido')
        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        )
        this.logradouro = data.logradouro
        this.bairro = data.bairro
        this.cidade = data.localidade
        this.estado = data.uf
      } catch (error) {
        console.error(error)
      } finally {
        this.fetchingCepData = false
      }
    },

    generateLeadCustomFields() {
      const mapFieldNameToData = {
        ['CEP']: this.cep,
        ['Logradouro']: this.logradouro,
        ['Número']: this.numero,
        ['Complemento']: this.complemento,
        ['Bairro']: this.bairro,
        ['Cidade']: this.cidade,
        ['Estado']: this.estado
      }
      return customFields['lead']
        .filter(cf => mapFieldNameToData[cf.name])
        .map(cf => ({
          field_id: cf.id,
          values: [{ value: mapFieldNameToData[cf.name] }]
        }))
    },

    generateContactCustomFields() {
      const mapFieldNameToData = {
        ['CPF']: this.cpf,
        ['E-mail']: this.email,
        ['Celular']: '+55' + this.celular.replace(/\D+/g, '')
      }
      return customFields['contact']
        .filter(cf => mapFieldNameToData[cf.name])
        .map(cf => ({
          field_id: cf.id,
          values: [{ value: mapFieldNameToData[cf.name], enum_id: cf.enum_id }]
        }))
    },

    validateEmail(email) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    },

    validateCpf(strCPF) {
      strCPF = strCPF.replace(/\D+/g, '')
      if (strCPF.length !== 11) return false
      if (strCPF == '00000000000') return false
      let resto,
        soma = 0
      for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
      resto = (soma * 10) % 11
      if (resto == 10 || resto == 11) resto = 0
      if (resto != parseInt(strCPF.substring(9, 10))) return false
      soma = 0
      for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
      resto = (soma * 10) % 11
      if (resto == 10 || resto == 11) resto = 0
      if (resto != parseInt(strCPF.substring(10, 11))) return false
      return true
    }
  }
}
</script>

<style scoped>
#form .row {
  margin: 0 -6px;
}

#form .col {
  padding: 0 6px;
}

#confirmation-message h1 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 2.5rem;
  color: white;
}

#confirmation-message p {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.5rem;
  color: white;
}
</style>
