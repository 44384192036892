const state = {
  windowWidth: undefined
};

const getters = {
  windowWidth(state) {
    return state.windowWidth;
  }
};

const mutations = {
  SET_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
  }
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};
