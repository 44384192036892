import Vue from "vue";
import Vuex from "vuex";
import Layout from "./modules/Layout";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: { Layout }
});

export default store;
