export const customFields = {
  lead: [
    { name: 'CEP', id: 1609096 },
    { name: 'Logradouro', id: 1609028 },
    { name: 'Número', id: 1609060 },
    { name: 'Complemento', id: 1609062 },
    { name: 'Bairro', id: 1609064 },
    { name: 'Cidade', id: 1609026 },
    { name: 'Estado', id: 1609024 },
    {
      name: 'Tipo de Caso',
      id: 1090250,
      enums: [
        { value: 'Aéreo - Atraso Voo Nacional', id: 660300 },
        { value: 'Aéreo - Atraso Voo Internacional', id: 660302 },
        { value: 'Aéreo - Overbooking', id: 660304 },
        { value: 'Aéreo - Bagagem Danificada', id: 660306 },
        { value: 'Aéreo - Extravio Bagagem', id: 660308 },
        { value: 'Aéreo- Multa Abusiva', id: 660312 },
        {
          value: 'Saúde - Cancelamento Abusivo do Plano de Saúde',
          id: 660422
        },
        {
          value: 'Saúde - Negativa Atendimento Urgência/Emergência',
          id: 660424
        },
        { value: 'Saúde - Negativa Home Care', id: 660426 },
        {
          value: 'Saúde - Negativa Exame/Procedimento Médico -',
          id: 660428
        },
        { value: 'Saúde - Negativa Medicamento', id: 660430 },
        {
          value: 'Imobiliário -  Cobrança indevida de Condomínio',
          id: 660432
        },
        { value: 'Imobiliário - Atraso Imóvel na Planta', id: 660434 },
        { value: 'Imobiliário - Distrato', id: 660436 },
        { value: 'Imobiliário - Baixa de Hipoteca', id: 660438 },
        { value: 'Imobiliário -  Vícios Construtivos', id: 660440 },
        { value: 'Telefonia - Portabilidade', id: 660442 },
        { value: 'Telefonia - Cobrança Indevida', id: 660444 },
        { value: 'Telefonia - Negativação Indevida', id: 660446 },
        { value: 'Telefonia - Suspensão do Serviço', id: 660448 },
        { value: 'Compras Online - Atraso na entrega', id: 660450 },
        {
          value: 'Compras Online - Direito de Arrependimento',
          id: 660452
        },
        {
          value: 'Compras Online - Demora reembolso/estorno',
          id: 660454
        },
        { value: 'Compras Online- Defeito/Vício do Produto', id: 660456 },
        {
          value: 'Produtos/Bens - Demora no Reparo em Garantia (art. 18 CDC)',
          id: 660460
        },
        {
          value: 'Produtos/Bens - Negativa Reparo em Garantia',
          id: 660462
        },
        {
          value: 'Produtos/Bens - Negativa Reparo Vida Útil',
          id: 660534
        },
        { value: 'Bancário-  Fraude Geral', id: 660536 },
        { value: 'Bancário - Fraude Cartão de Crédito', id: 660538 },
        { value: 'Bancário - Negativação Indevida', id: 660540 },
        { value: 'Bancário - Cobrança Indevida', id: 660542 },
        { value: 'Trabalhista - Aérea Médica Pjotização', id: 660544 },
        { value: 'Trabalhista - Reconhecimento de Vínculo', id: 660546 },
        {
          value: 'Trabalhista - Não Pagamento de Verbas Rescisória',
          id: 660548
        },
        { value: 'Trabalhista- Acidente de Trabalho', id: 660550 },
        { value: 'Trabalhista - Estabilidade Gestante', id: 660552 },
        { value: 'Trabalhista - Dispensa Discriminatória', id: 660554 },
        {
          value: 'Condominío - Ação de Cobrança de Cota Comdominail',
          id: 660556
        },
        { value: 'Trabalhista- Pjotização', id: 660594 },
        { value: 'Bancário - Golpe Motoboy', id: 660788 },
        { value: 'Negativação Indevida - Geral', id: 667528 },
        { value: 'Manutenção Indevida SPC/SERASA', id: 667530 }
      ]
    },
    { name: 'Descrição do Caso', id: 1268876 },
    { name: 'Parte Contrária 1', id: 1288030 },
    { name: 'Parte Contrária 2 ', id: 1926788 },
    { name: 'Parte Contrária 3', id: 1926790 },
    {
      name: 'Origem do Lead',
      id: 1288180,
      enums: [
        { value: 'Instagram', id: 779224 },
        { value: 'Whatsapp', id: 779226 },
        { value: 'Facebook', id: 779228 },
        { value: 'Indicação Cliente', id: 779230 },
        { value: 'Vendas Cível', id: 779232 },
        { value: 'Vendas Trabalhista', id: 779234 },
        { value: 'Jusbrasil', id: 788564 }
      ]
    }
  ],
  contact: [
    { name: 'E-mail', id: 1089088, enum_id: 659622 },
    { name: 'CPF', id: 1111808 },
    { name: 'Celular', id: 1089086, enum_id: 659612 }
  ]
}
