<template>
  <ClientInformationFormDesktop v-if="windowWidth >= 720" />
  <ClientInformationFormMobile v-else />
</template>

<script>
import ClientInformationFormDesktop from "./desktop/ClientInformationFormDesktop";
import ClientInformationFormMobile from "./mobile/ClientInformationFormMobile";

export default {
  components: { ClientInformationFormDesktop, ClientInformationFormMobile },

  computed: {
    windowWidth() {
      return this.$store.getters.windowWidth;
    }
  }
};
</script>
